export const config = {
    title: 'Haven',
    name: "Haven Sampoorna",
    image: '',
    logo: 'https://d18xkwaipu1whh.cloudfront.net/CommonAsset/logo.jpg',
    DOMAIN_NAME : 'https://www.havensampoorna.in',
    USER_ID : 16,
    STORE_ID: 1,
    ORGANIZATION_ID:1,
    default_product_image: "",
    product_path:"/product",
    showTableBookingBtn:true,
    noImageAvailable:"https://d18xkwaipu1whh.cloudfront.net/TamsAsset/NoImage.jpg",
    pattern1: "!@#$%^",
    pattern2: "8172",
    pattern3: "havensampoorna",

    HOME_SETTINGS: {
      banner: [
        {
          name: "Home Banner",
          image: "https://d18xkwaipu1whh.cloudfront.net/CommonAsset/banner.jpg",
          quote: "<h3>Good Food <br> Good Feelings</h3><p>with <span>Haven Sampoorna</span></p>",
          button: {
            name: "CHECK OUT OUR MENU",
            link: "product"
          }
        }
      ],
      about_st: [
        {
          title: "FAST DELIVERY",
          image: "assets/images/vendor/home1.svg",
          text: "We are striving hard behind the scenes to deliver your food as quickly as possible"
        },
        {
          title: "FRESH FOOD",
          image: "assets/images/vendor/home2.svg",
          text: "We use only the best quality ingredients to whip up tasty food for you"
        },
        {
          title: "EXPERIENCED CHEFS",
          image: "assets/images/vendor/home3.svg",
          text: "Our chefs and cooks boast many years of experience"
        },
        {
          title: "A VARIETY OF DISHES",
          image: "assets/images/vendor/home4.svg",
          text: "Our menu has a variety of dishes, desserts, and drinks to tingle your taste buds and suit your mood"
        }
      ],
      gutters: [
        {
          image: "https://d18xkwaipu1whh.cloudfront.net/CommonAsset/img1.jpg"
        },
        {
          image: "https://d18xkwaipu1whh.cloudfront.net/CommonAsset/img2.jpg"
        },
        {
          image: "https://d18xkwaipu1whh.cloudfront.net/CommonAsset/img3.jpg"
        },
        {
          image: "https://d18xkwaipu1whh.cloudfront.net/CommonAsset/img4.jpg"
        }
      ],
      testimonials: [
        {
          title: "LOVE FROM HAVEN SAMPOORNA FAM",
          comment: "I got a very quick of delivery the food. The food is awesome. <br>It's excellent quality and value for your money.Highly recommended. Good job team Haven Sampoorna",
          name: "Swati Savera"
        }
      ],
      float_button: {
        show: false,
        image: "assets/images/tams/online.svg",
        link: "/product"
      }
    },
    menu: [
      {
        id: "home",
        label: "Home",
        link: "/"
      },
      {
        id: "menus",
        label: "Menus",
        link: "product"
      },
      {
        id: "tableBooking",
        label: "Table Booking",
        link: "table-booking"
      },
      {
        id: "giftAMeal",
        label: "Gift An Experience",
        link: "gift-meal"
      },
      {
        id: "about",
        label: "About",
        link: "about-us"
      },
      {
        id: "contact",
        label: "Contact",
        link: "contact-us"
      }
    ],
    footer: [
      {
        id: "about",
        label: "About Us",
        link: "about-us"
      },
      {
        id: "contact",
        label: "Contact Us",
        link: "contact-us"
      },
      {
        id: "tableBooking",
        label: "Table Booking",
        link: "table-booking"
      },
      {
        id: "giftAMeal",
        label: "Gift An Experience",
        link: "gift-meal"
      },
      // {
      //   id: "faq",
      //   label: "FAQ",
      //   link: "FAQs"
      // },
      {
        id: "termsandconditions",
        label: "Terms & conditions",
        link: "terms-and-condition"
      },
      {
        id: "privacypolicy",
        label: "Privacy Policy",
        link: "privacy-policy"
      }
    ]
  };
