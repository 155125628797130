<app-alerts *ngIf="showalertmsg"></app-alerts>
<footer class="mt-5">
    <!-- newsletter -->
    <section class="inq-news-st">
        <div class="row justify-content-center">
            <div class="col-md-9">
                <div class="row inq-com-pd-st">
                    <div class="col-md-7">
                        <h5 class="text-uppercase">SIGN UP FOR OUR NEWSLETTER TO GET ALL UPDATES!</h5>
                        <!-- <p>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum </p> -->
                    </div>
                    <div class="col-md-5">
                        <div class="inq-news-fld-st ">
                            <form [formGroup]="subscribeForm" novalidate style="padding-bottom: 30%;">
                                <input type="text" class="form-control" placeholder="Your Email Id..."
                                    formControlName="email" [class.is-invalid]="f.email.invalid && validateForm"
                                    required>
                                <div class="invalid-feedback" *ngIf="f.email.errors">
                                    <div *ngIf="f.email.errors.required">
                                        Email Id is required.
                                    </div>
                                    <div *ngIf="f.email.errors.pattern">
                                        Enter correct Email Id.
                                    </div>
                                    <div *ngIf="f.email.errors.maxlength">
                                        Email can't be more than 50 characters long.
                                    </div>
                                </div>
                                <span class="text-uppercase" (click)="subscribe()">subscribe</span>
                            </form>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    </section>
    <!-- newsletter -->
    <div class="container">
        <div class="inq-foot-bg-st">
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div class="row inq-com-pd-st">
                        <div class="col-md-4" *ngIf="config.title!='Tams'">
                            <div class="" *ngIf='footer1'>
                                <h5 class="" *ngIf="config.title!='Haven'">OFFICE</h5>
                                <h5 class="" *ngIf="config.title==='Haven'">OUTLETS</h5>
                                <div data-toggle="tooltip" data-placement="top" title="Branch Location" style="cursor:pointer">
                                <h6 (click)="openBranchLocation('branch1')">{{footer1.name}}</h6>
                                <ul>
                                    <li (click)="openBranchLocation('branch1')"><i class="fa fa-map-marker"></i> {{footer1.address1}}, <br>{{footer1.city}},
                                        {{footer1.state}} - {{footer1.zipcode}}</li>
                                    <li (click)="openBranchLocation('branch1')"><i class="fa fa-phone"></i> <a style="color: #ffffff !important;"
                                            > {{footer1.phone}}</a></li>
                                    <li *ngIf="config.title!='Tams'"><i class="fa fa-envelope"></i><a href="mailto: footer1.email" style="color: #ffffff !important;"> {{footer1.email}}</a></li>                                           
                                            <li *ngIf="showCertificate">
                                                <img src="assets/images/tams/fssai.svg">
                                            </li>
                                            <li *ngIf="showCertificate">
                                                {{license}}
                                            </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        
                        
                        <div class="col-md-8" *ngIf="config.title==='Tams'">
                            <div class="" *ngIf='footer1'>
                                <h6>{{footer1.name}}</h6>
                                <ul>
                                    <li><i class="fa fa-map-marker"></i> {{footer1.address1}}, <br>{{footer1.city}},
                                        {{footer1.state}} - {{footer1.zipcode}}</li>
                                    <li><i class="fa fa-phone"></i> <a style="color: #ffffff !important;"
                                            > {{footer1.phone}}</a></li>
                                    <li *ngIf="config.title!='Tams'"><i class="fa fa-envelope"></i><a style="color: #ffffff !important;"> {{footer1.email}}</a></li>
                                    <li *ngIf="config.title === 'Tams'"><i class="fa fa-envelope"></i><a style="color: #ffffff !important;">feedback@tamsfoods.com</a></li>                                           
                                            <li *ngIf="showCertificate">
                                                <img src="assets/images/tams/fssai.svg">
                                            </li>
                                            <li *ngIf="showCertificate">
                                                {{license}}
                                            </li>
                                </ul>
                            </div>
                        </div>

                        <div *ngIf="config.title==='Tams'" class="col-md-4">
                            <button class="btn btn-primary inq-btn-st ml-1" routerLink="/contact-us">contact us</button>
                            <div class="" *ngIf='footer1'>
                                <ul>        <li *ngIf="config.title==='Tams'"><a style="color: #ffffff !important;">For Trade Enquiries: </a></li>
                                            <li *ngIf="config.title==='Tams'"><i class="fa fa-envelope"></i><a style="color: #ffffff !important;">{{footer1.email}}</a></li>
                                            <li *ngIf="config.title==='Tams'"><i class="fa fa-phone"></i><a style="color: #ffffff !important;">+91 9004239672</a></li>
                                
                                </ul>
                            </div>
                        </div>
                        
                        <div class="col-md-4">
                            <div class="" *ngIf='footer2'>
                                <br>
                                <div data-toggle="tooltip" data-placement="top" title="Branch Location" style="cursor:pointer">
                                <h6 (click)="openBranchLocation('branch2')">{{footer2.name}}</h6>
                                <ul>
                                    <li (click)="openBranchLocation('branch2')"><i class="fa fa-map-marker"></i> {{footer2.address1}}, <br>{{footer2.city}},
                                        {{footer2.state}} - {{footer2.zipcode}}</li>
                                    <li (click)="openBranchLocation('branch2')"><i class="fa fa-phone"></i><a style="color: #ffffff !important;"
                                            > {{footer2.phone}}</a></li>
                                    <li><i class="fa fa-envelope"></i><a  href="mailto: footer2.email" style="color: #ffffff !important;"
                                            > {{footer2.email}}</a></li>
                                    
                                </ul>
                                </div>
                            </div>
                            <!-- <div *ngIf="config.title == 'Tams'">
                                <h5 class="inq-visi-st text-white">CONTACT US</h5>
                                <h6>CONTACT US</h6>
                                <li ><i class="fa fa-phone"></i> <a style="color: #ffffff !important;"
                                    [href]='calltoexp1'> {{footer1.phone}}</a></li>
                            <li><i class="fa fa-envelope"></i><a style="color: #ffffff !important;"
                                    [href]='mailtoexp1'> {{footer1.email}}</a></li>
                                    {{mailtoexp1}}
                            </div> -->
                        </div>
                        <div class="col-md-4">
                            <div class="" *ngIf="footer2 && config.title!='Tams'">
                                <h5 *ngIf="config.title!='Haven'">TIMINGS</h5>
                                <h5 *ngIf="config.title==='Haven'">OUTLET WORKING TIME</h5>
                                <ul>
                                    <li *ngFor='let timing of footer2.pickup_timings'><img
                                            src="assets/images/vendor/time.svg" alt="clock"> {{timing.shift_title}} -
                                        {{timing.start_hour}} to {{timing.end_hour}}</li>
                                    <!-- <li><img src="assets/images/vendor/time.svg" alt="clock"> {{footer2.pickup_timings[0].shift_title}} - {{footer2.pickup_timings[0].start_hour}} to {{footer2.pickup_timings[0].end_hour}}</li>
                                    <li><img src="assets/images/vendor/time.svg" alt="clock"> {{footer2.pickup_timings[1].shift_title}} - {{footer2.pickup_timings[1].start_hour}} to {{footer2.pickup_timings[1].end_hour}}</li> -->
                                    <li class="mt-3" style="margin-top: 2rem !important;" *ngIf="showBookTableBtn"><button class="btn btn-primary inq-btn-st"
                                            (click)="bookYourTable()">BOOK A TABLE</button></li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>

                </div>
            </div>
            <div class="inq-footql-st" [class]="config.title=='Mayan'? 'footer-mayan' : (config.title == 'Murgasen' ? 'footer-murugesan' : '')" >
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div *ngIf="config.title !== 'Haven'&&config.title !== 'Nesma'" class="inq-foot-logo-st">
                                            <img [src]="logoImage" alt="Logo" style="max-height: 60px !important;min-height: 60px!important; width: 100px !important;">
                                        </div>
                                        <div *ngIf="config.title === 'Haven'">
                                            <!-- <img [src]="logoImage" alt="Logo" style="max-height: 60px !important;min-height: 60px!important; width: 95px !important;"> -->
                                            <img [src]="logoImage" alt="Logo" style="max-height: 60px !important;min-height: 60px!important; width: 118px !important;">

                                        </div>
                                    </div>
                                    <div class="col-md-8" *ngIf="!showCertificate">
                                        <!-- <p *ngIf="config.title === 'Haven'" class="inq-foot-pd-st">&copy; Sampoorna Catering Foods | All Rights Reserved</p> -->
                                        <p *ngIf="config.title === 'Tams'" class="inq-foot-pd-st">&copy; 2021 TAMS | All Rights Reserved</p>
                                        <p *ngIf="config.title === 'Murgasen'" class="inq-foot-pd-st">&copy; 2021 Murugesan Stores | All Rights Reserved</p>
                                        <p *ngIf="config.title === 'Mayan'" class="inq-foot-pd-st">&copy; 2021 Mayan Stores | All Rights Reserved</p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="inq-foot-links-st inq-foot-pd-st">
                                    <ul>
                                        <li class="pointer" *ngFor="let foot of footer;" routerLink={{foot.link}}
                                            (click)='scrollFunction()' style="padding-right: 5px;">{{foot.label}}</li>
                                    </ul>

                                </div>
                                <div class="footer-row-two1" *ngIf="config.title === 'Haven'">
                                    <div class="s-icons">
                                        <ul class="social-icons">
                                            <!-- <li>
                                                <a href="#">
                                                    <i class="fa fa-facebook"></i>
                                                </a>
                                            </li> -->
                                            <!-- <li>
                                                <a href="#">
                                                    <i class="fa fa-twitter"></i>
                                                </a>
                                            </li> -->
                                            <li>
                                                <a style="cursor: pointer;" (click)="instagramOpen()">
                                                    <!-- <i class="fa fa-linkedin"></i> -->
                                                    <i class="fa fa-instagram" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <!-- <i class="fa fa-google-plus" aria-hidden="true"></i> -->
                                                </a>
                                            </li>
                                            <!-- <li>
                                                <a href="#">
                                                    <i class="fa fa-pinterest" aria-hidden="true"></i>
                                                </a>
                                            </li> -->
                            
                                        </ul>
                                        <ul>
                                            <p *ngIf="config.title === 'Haven'" >&copy; Sampoorna Catering Foods | All Rights Reserved</p>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <!--<div class="inq-footql-st" [class.footer-mayan] = "config.title === 'Mayan'">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="inq-foot-logo-st">
                                            <img src="{{config.logo}}" alt="Logo" style="max-height: 60px !important;min-height: 60px!important; width: 100px !important;">
                                        </div>
                                    </div>
                                    <div class="col-md-8" *ngIf="!showCertificate">
                                        <p *ngIf="config.title === 'Haven'" class="inq-foot-pd-st">&copy; Sampoorna Catering Foods | All Rights Reserved</p>
                                        <p *ngIf="config.title === 'Tams'" class="inq-foot-pd-st">&copy; 2021 TAMS | All Rights Reserved</p>
                                        <p *ngIf="config.title === 'Murgasen'" class="inq-foot-pd-st">&copy; 2021 Murugesan Stores | All Rights Reserved</p>
                                        <p *ngIf="config.title === 'Mayan'" class="inq-foot-pd-st">&copy; 2021 Mayan Stores | All Rights Reserved</p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="inq-foot-links-st inq-foot-pd-st">
                                    <ul>
                                        <li class="pointer" *ngFor="let foot of footer;" routerLink={{foot.link}}
                                            (click)='scrollFunction()' style="padding-right: 5px;">{{foot.label}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</footer>